import React from "react";
import { graphql } from "gatsby";
import { Layout, Col, Row } from "antd";
import PublicLayout from "../components/PublicLayout";
import Categories from "./components/blog/categories";
import Posts from "./components/blog/posts";
import Header from "./components/blog/header";
import SEO from "../components/seo";
import imageUrlReplace from "./components/blog/imageUrlReplace";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const BlogPage = (props) => {
  const {
    data: {
      wpgraphql: { posts },
    },
    pageContext: { pageNumber, hasNextPage, categories },
  } = props;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }];


  return (
    <PublicLayout page="blog">
      <SEO
        title={t('seo.blog.title')}
        description={t('seo.blog.description')}
        image={imageUrlReplace(posts.nodes.image?.featuredImage.node.sourceUrl)}
        dataLayer={{
          userId: "",
          pageId: "560",
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className="blog-container">
        <Content>
          <Row justify="center">
            <Col lg={24} xs={24}>
              <Header t={t} />
              <Categories categories={categories} />
              <Posts
                posts={posts}
                pageNumber={pageNumber}
                hasNextPage={hasNextPage}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  )
};

export default BlogPage;


export const postQuery = graphql`
  query GET_POSTS($ids: [ID], $language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wpgraphql {
      posts(where: { in: $ids }) {
        nodes {
          id
          title
          date
          excerpt
          content
          slug
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              sourceUrl
              altText
              title
            }
          }
        }
      }
    }
  }
`;
